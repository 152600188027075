import React, { useState, useEffect } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons'; 

const serverUrl = process.env.REACT_APP_SERVER_URL;

const Register = () => {
  const [formData, setFormData] = useState({
    username: '',
    mobile: '',
    email: '',
    password: '',
  });

  const [message, setMessage] = useState({ type: '', text: '' });

  const handleCloseMessage = () => {
    setMessage({ type: '', text: '' });
  };

  useEffect(() => {
    // Set a timeout to automatically close the alert after 5 seconds
    const timeout = setTimeout(() => {
      handleCloseMessage();
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [message]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
	e.preventDefault();
	try {
	  const response = await axios.post(serverUrl + '/register', formData);
  
	  if (response.status === 201) {
		setMessage({ type: 'success', text: 'Registration successful' });
		// Clear the form data by resetting the state
		setFormData({
		  username: '',
		  mobile: '',
		  email: '',
		  password: '',
		});
	  }
	} catch (error) {
		if (error.response && error.response.status === 400) {
		  setMessage({ type: 'danger', text: 'Mobile number already in use' });
	} 
	else if (error.response && error.response.status === 410) {
		setMessage({ type: 'danger', text: 'Email address already in use' });
  	} 
	else {
		setMessage({ type: 'danger', text: 'Registration Error' });
	  }
	}
  };
  

  return (
    <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
      <div className="bg-white p-5 rounded shadow" style={{ width: '80%', margin: '0 auto', padding: '2rem' }}>
        <h3 style={{ color: '#5A4BFF', fontWeight: 'bolder' }}>Register</h3>
        <Form onSubmit={handleSubmit} style={{ marginTop: '1rem' }}>
          <Form.Group controlId="username">
            <Form.Label style={{ fontSize: '1.2rem', fontWeight: 'bold', color: 'grey' }}>Username</Form.Label>
            <Form.Control
              type="text"
              name="username"
			  autoComplete='username'
              placeholder='Enter your Name'
              value={formData.username}
              onChange={handleInputChange}
              required
              className="w-100"
              style={{ marginTop: '0.5rem', marginBottom: '1rem', color: 'grey' }}
            />
          </Form.Group>

          <Form.Group controlId="mobile">
            <Form.Label style={{ fontSize: '1.2rem', fontWeight: 'bold', color: 'grey' }}>Mobile</Form.Label>
            <Form.Control
              type="text"
              name="mobile"
			  autoComplete='mobile'
              placeholder='Enter your Mobile Number'
              value={formData.mobile}
              onChange={handleInputChange}
              required
              className="w-100"
              style={{ marginTop: '0.5rem', marginBottom: '1rem', color: 'grey' }}
            />
          </Form.Group>

          <Form.Group controlId="email">
            <Form.Label style={{ fontSize: '1.2rem', fontWeight: 'bold', color: 'grey' }}>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
			  autoComplete='email'
              placeholder='Enter your email'
              value={formData.email}
              onChange={handleInputChange}
              required
              className="w-100"
              style={{ marginTop: '0.5rem', marginBottom: '1rem', color: 'grey' }}
            />
          </Form.Group>

          <Form.Group controlId="password">
            <Form.Label style={{ fontSize: '1.2rem', fontWeight: 'bold', color: 'grey' }}>Password</Form.Label>
            <Form.Control
              type="password"
              name="password"
			  autoComplete='new-password'
              placeholder='Enter your Password'
              value={formData.password}
              onChange={handleInputChange}
              required
              className="w-100"
              style={{ marginTop: '0.5rem', marginBottom: '2rem', color: 'grey' }}
            />
          </Form.Group>

          <Button variant="primary" type="submit" className="w-100" style={{ fontWeight: 'bold', fontSize: '1.3rem', backgroundColor:'#5A4BFF' }}>
            Register
          </Button>
        </Form>
      </div>
	  
      {message.text && (
        <Alert key={message.type} variant={message.type} style={{ position: 'absolute', top: '80%', left: '50%', transform: 'translateX(-50%)', padding: '2rem' }}>
          {message.text}
          <Button onClick={handleCloseMessage} variant="outline-secondary" size="sm" style={{ position: 'absolute', top: '0', right: '0', backgroundColor:'red' , color:'white', border:'none'}}>
		  <FontAwesomeIcon icon={faXmark} />
          </Button>
        </Alert>
      )}
    </Container>
		 
  );
};

export default Register;
