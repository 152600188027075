import React, { useEffect, useState } from 'react';
import axios from 'axios';

const serverUrl = process.env.REACT_APP_SERVER_URL;
function ChatPage({ userId }) {
	const [chatData, setChatData] = useState([]);
	
	
  useEffect(() => {
    // Fetch chat data for the given user from your API
    axios.get(serverUrl + `/api/chat?userId=${userId}`)
      .then((response) => {
        // Organize chat data by date
        const organizedChatData = organizeChatData(response.data);
        setChatData(organizedChatData);
      })
      .catch((error) => {
        console.error('Failed to fetch chat data:', error);
      });
  }, [userId]);

  // Helper function to organize chat data by date
  const organizeChatData = (chatMessages) => {
	if (!chatMessages || chatMessages.length === 0) {
	  return [{ date: 'No chat messages available', messages: [] }];
	}
  
	const organizedData = [];
	let currentDate = null;
  
	chatMessages.forEach((message) => {
	  const messageDate = new Date(message.timestamp).toDateString();
  
	  if (messageDate !== currentDate) {
		// Create a new date group
		currentDate = messageDate;
		organizedData.push({ date: currentDate, messages: [message] });
	  } else {
		// Add the message to the current date group
		organizedData[organizedData.length - 1].messages.push(message);
	  }
	});
  
	return organizedData;
  };
  

  return (
	<div className="container-fluid mt-1 pd-0">
		<div className="row">
			<div className="col-md-12 d-flex justify-content-center align-items-center">
	<div className='chats-page' style={{marginTop: '7rem'}}>
	{chatData.map((dateGroup) => (
	  <div key={dateGroup.date} >
		<div className="date-separator text-center m-4" >
		  {dateGroup.date}
		</div>
		{dateGroup.messages.map((message) => (
		  <div key={message._id} className={`chat-message ${message.role === 'user' ? 'chat' : 'chat bot'}`} style={{flexDirection:'column'}}>
			 <div className="message-content">
              {message.content}
            </div>
            <div className="timestamp text-muted" >
              {new Date(message.timestamp).toLocaleTimeString()}
            </div>
		  </div>
		))}
	  </div>
	))}
  </div>
  </div>
  </div>
  </div>
  
  );
}

export default ChatPage;
