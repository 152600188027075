import OpenAI from "openai";
import axios from "axios";

const userName = localStorage.getItem('userName');
const userId = localStorage.getItem('userId'); 
const serverUrl = process.env.REACT_APP_SERVER_URL;

const openai = new OpenAI({
    apiKey: process.env.REACT_APP_API_KEY,
	dangerouslyAllowBrowser: true
});

let conversationHistory = [];  // Initialize this at the start of the conversation

export async function* sendMsgToOpenAI(userMessage ) {
	const systemMessage = `
	During this conversation, please assume the role of a well-versed and attentive skills assessment professional called Ignite AI. 
	Your responses should center around evaluating a client's skills, strengths, and weaknesses, and give a detailed report on the above parameters.
	tou will ask questions from the topics below. you will ask one question recieve the answer for this question and then ask the next question.
	You will start the evaluation by asking them for their education domain 
	the next question is about their strengths they think they posses in life.
	the next question will be about hobbies.
	the next question will be about weaknesses.
	then next question is about what career path they would like to choose, to be an enterpreuner or an job.
	for all the subsequent questions give multiple choices with 4 options, alphabets as key and ask short questions of max 15 words. 
	the next question is based on Big Five Personality Test. 
	the next question is based on soft skills. 
	the next question is based on tasks management.
	the next question is based on interest and passion.
	the next question is based on Ethical and Professional Conduct. 
	the next question is based on emotional intelligence. 
	the next question is based on personality traits.
	the next question is based on cognitive ablities.
	the next question is based on interpersonal skills.
	the next question is based on motivation and goals.
	the next question is based on career goals.
	after the final question generate a report that has bulleted points with titles skills, strengths, weaknesses. limit the report within 140 tokens. donot add anything extra after weaknesses.
	If you understand, please reply only by saying 'Hi ${userName}'.  Use very short sentences and use simple language that those who have a lower understanding of English, can understand.
	the ignite team can offer guidance on your startup ideas, mentorships on your academic projects, offer internships on various areas, offer career guidance. for more details you can give https://ignite.cblu.io
	`;
	


  conversationHistory.push({ role: "user", content: userMessage }); 
  // Send the message to your server for storage
axios.post(serverUrl + '/chat', { userId, role: 'user', content: userMessage })
.then((response) => {
})
.catch((error) => {
  console.error('Failed to save user message:', error);
});

  const conversation = [
      { role: "system", content: systemMessage },
      ...conversationHistory  // Spreading the conversation history
  ];

  const res = await openai.chat.completions.create({
    messages: conversation,
    model: "gpt-3.5-turbo",
    temperature: 0.7,
    max_tokens: 350,
	stream: true},
	{
		responseType: 'stream'
	});
	console.log(conversation);
	try {
		let combinedContent = '';
		for await (const part of res) {
		  const content = part.choices[0]?.delta?.content || '';
		//   console.log(content);
		combinedContent += content;
		  yield content;
		}
		const assistantMessage = combinedContent;
		console.log(combinedContent);
		axios.post(serverUrl + '/chat', { userId, role: 'assistant', content: assistantMessage })
		.then((response) => {
		})
		.catch((error) => {
			console.error('Failed to save assistant message:', error);
		});
		conversationHistory.push({ role: "assistant", content: assistantMessage });
	  } catch (error) {
		console.error('Error processing stream:', error);
	  }

} 