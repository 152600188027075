
// SideBar.js
import React, { useState, useEffect } from 'react';

import gptLogo from '../assets/ignitepuple.svg';
import ignite_logo from '../assets/ignitepuple64x64.svg';
import addBtn from '../assets/add-30.png';
import msgIcon from '../assets/message.svg';

import { handleQuery } from '../functions/queryhandler';

function SideBar({ isSidebarHidden, setMessages, setIsTyping, setLoading, setTypingText }) {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);
  

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function resetChatState() {
	// Clear chat messages
	setMessages([]);
	
  }

  return (
    <div className={`sideBar ${isSmallScreen ? 'smallSidebar' : ''} ${isSidebarHidden ? 'hiddenSidebar' : ''}`}>
      <div className="upperSide">
        <div className="upperSideTop">
          <img src={isSmallScreen ? ignite_logo : gptLogo} alt="logo"  className={`${isSmallScreen ? 'smallLogo' : 'logo'}`} />      
          <button className={`midBtn ${isSmallScreen ? 'smallBtn' : ''}`} onClick={resetChatState}>
            <img src={addBtn} alt="new chat" className={`addBtn ${isSmallScreen ? 'smallAddBtn' : ''}`} />{isSmallScreen ? '' : 'New Chat'}
          </button>
          <button className={`${isSmallScreen ? 'smallQuery' : 'query'}`}  onClick={() => handleQuery("Lets start my skills assessment ?", setMessages, setIsTyping,  setLoading, setTypingText)}>
		  {isSmallScreen ? null : <img src={msgIcon} alt="" />}{isSmallScreen ? 'skills Assesment' : 'Lets start my skills assessment'}
          </button>
		  <button className={`${isSmallScreen ? 'smallQuery' : 'query'}`}  onClick={() => handleQuery("what is ignite AI?", setMessages, setIsTyping,  setLoading, setTypingText)}>
		  {isSmallScreen ? null : <img src={msgIcon} alt="" />}{isSmallScreen ? 'About Ignite' : "what is ignite AI?"}
          </button>
		  <button className={`${isSmallScreen ? 'smallQuery' : 'query'}`}  onClick={() => handleQuery("Tell me About Ignite Academic Project Mentorship", setMessages, setIsTyping,  setLoading, setTypingText)}>
		  {isSmallScreen ? null : <img src={msgIcon} alt="" />}{isSmallScreen ? 'Academic Projects' : "Tell me About Ignite Academic Project Mentorship"}
          </button>
		  <button className={`${isSmallScreen ? 'smallQuery' : 'query'}`}  onClick={() => handleQuery("Tell me About Ignite career guidance program ?", setMessages, setIsTyping,  setLoading, setTypingText)}>
		  {isSmallScreen ? null : <img src={msgIcon} alt="" />}{isSmallScreen ? 'Career Guidance' : "Tell me About Ignite career guidance program ?"}
          </button>
		  <button className={`${isSmallScreen ? 'smallQuery' : 'query'}`}  onClick={() => handleQuery("Tell me About Ignite Internship Program ?", setMessages, setIsTyping,  setLoading, setTypingText)}>
		  {isSmallScreen ? null : <img src={msgIcon} alt="" />}{isSmallScreen ? 'Internship Programs' : "Tell me About Ignite Internship Program ?"}
          </button>
		  <button className={`${isSmallScreen ? 'smallQuery' : 'query'}`}  onClick={() => handleQuery("Tell me About Ignite startup mentorship ?", setMessages, setIsTyping,  setLoading, setTypingText)}>
		  {isSmallScreen ? null : <img src={msgIcon} alt="" />}{isSmallScreen ? 'Startup Mentorship' : "Tell me About Ignite Startup Mentorship ?"}
          </button>
          {/* Add other buttons */}
        </div>
      </div>
    </div>
  );
}

export default SideBar;

