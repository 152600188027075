// queryHandler.js
import { sendMsgToOpenAI } from '../openai';


export async function handleQuery(query, setMessages, setIsTyping,  setLoading, setTypingText) {
  setLoading(true);

  const userMessage = { content: query, role: 'user' };
  setMessages((prevMessages) => {
    if (Array.isArray(prevMessages)) {
      return [...prevMessages, userMessage];
    } else {
      return [userMessage];
    }
  });

  setIsTyping(true);

try {
	  const resGenerator = sendMsgToOpenAI(query);
	  let combinedContent = '';
	  let firstTokenReceived = false;
  
	  for await (const contentPart of resGenerator) {
		combinedContent += contentPart;
  
		if (!firstTokenReceived) {
		  setTimeout(() => {
			setLoading(false);
		  }, 100);
		  firstTokenReceived = true;
		}
  
		// Stream the data to the user
		setTypingText(combinedContent);
	  }
  
	  // Once the stream is complete, update the messages array with the full response
	  setMessages((prevMessages) => [...prevMessages, { role: 'assistant', content: combinedContent }]);
	} catch (error) {
	  console.error('Error handling streaming response:', error);
	} finally {
	  setIsTyping(false);
	}
}

export async function handleSend(input, setMessages, setIsTyping, setLoading, setTypingText) {
	setLoading(true);
	setIsTyping(true);
  
	const userMessage = { content: input, role: 'user' };
	setMessages((prevMessages) => [...prevMessages, userMessage]);
  
	try {
	  const resGenerator = sendMsgToOpenAI(input);
	  let combinedContent = '';
	  let firstTokenReceived = false;
  
	  for await (const contentPart of resGenerator) {
		combinedContent += contentPart;
  
		if (!firstTokenReceived) {
		  setTimeout(() => {
			setLoading(false);
		  }, 100);
		  firstTokenReceived = true;
		}
  
		// Stream the data to the user
		setTypingText(combinedContent);
	  }
  
	  // Once the stream is complete, update the messages array with the full response
	  setMessages((prevMessages) => [...prevMessages, { role: 'assistant', content: combinedContent }]);
	} catch (error) {
	  console.error('Error handling streaming response:', error);
	} finally {
	  setIsTyping(false);
	}
  }
  
  
  
  
 
  

