import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import userIcon from './assets/user-icon.png';
import gptImageLogo from './assets/ignitebgpurple.svg';
import { handleSend } from './functions/queryhandler';
import { useState, useRef, useEffect} from 'react';
import axios from 'axios';
import SideBar from './components/sidebar';
import LeftSideBar from './components/leftsidebar';
import MainContent from './components/maincontent';
import ChatFooter from './components/chatfooter';
import NavigationBar from './components/navbar';
import Login from './components/Login';
import Register from './components/Register';
import ChatPage from './components/ChatPage';


function App() {
  const msgEnd = useRef(null);
  const [input, setInput] = useState("");
//   const [skills, setSkills] = useState([]);
//   const [strengths, setStrengths] = useState([]);
//   const [weaknesses, setWeaknesses] = useState([]);

  const [typingText, setTypingText] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSidebarHidden, setIsSidebarHidden] = useState(false);
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(!!localStorage.getItem('userToken'));
  const serverUrl = process.env.REACT_APP_SERVER_URL;
//   const userName = localStorage.getItem('userName');
//   const personalizedGreeting = `Hi ${userName}! How can I assist you today?`;
const userId = localStorage.getItem('userId');

const [messages, setMessages] = useState([ 
      {
        content: "Hi, I am Ignite AI, how may I assist you",
        role: 'assistant',
      },
    
	]);
  
const toggleSidebar = (value) => {
	  setIsSidebarHidden(value);
	}


  const handleEnter = async (e, setLoading) => {
    if (e.key === 'Enter') {
      setInput('');
      await handleSend(input, setMessages, setIsTyping,  setLoading, setTypingText);
    }
  }
  useEffect(() => {
	const fetchChatMessages = async () => {
	  if (userId) {
		try {
		  const response = await axios.get(serverUrl + `/api/chattoday?userId=${userId}`);
		  const chatMessagesData = response.data;
		  setMessages(chatMessagesData);
		} catch (error) {
		  console.error('Failed to fetch chat messages:', error);
		}
	  }
	};
	fetchChatMessages();
  }, [userId, serverUrl]);

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);




  return (
	
    <Router>
      <div className="App">
		<NavigationBar toggleSidebar={toggleSidebar}/>
        <Routes>
          <Route path="/login" element={<Login onLogin={() => setUserIsLoggedIn(true)} />}/>
          <Route path="/register"element={<Register />}/> 
          <Route path="/chats"element={userIsLoggedIn ? 
		  <ChatPage userId={userId} /> :(<Navigate to="/login" /> )
		  }/> 
          <Route path="*" element={ userIsLoggedIn ? (
				<div className="container-fluid mt-1 pd-0">
						  <div className="row">
						  <div className={`${isSmallScreen ? (isSidebarHidden ? 'smallSbHidden': 'smallSb') : (isSidebarHidden ? 'lgSidebarHidden' : 'col-md-3 marginTopAdjust' )}`}>
								  <SideBar
								  	isSidebarHidden={isSidebarHidden}
									  setLoading={setLoading}
									  setMessages={setMessages}
									  setIsTyping={setIsTyping}
									  setTypingText={setTypingText}
									  />
							  </div>
							  <div className={`${isSmallScreen ? (isSidebarHidden ? 'smallChatBoxHidden marginTopAdjust': 'smallChatBox') : (isSidebarHidden ? 'hiddenSidebar col-md-12 marginTopAdjust':'col-md-8 marginTopAdjust')} `}>
								  <div className="main">
									  <MainContent
										  messages={messages}
										  isTyping={isTyping}
										  typingText={typingText}
										  loading={loading}
										  gptImageLogo={gptImageLogo}
										  userIcon={userIcon}
										  msgEnd={msgEnd} />
									  <ChatFooter
										  input={input}
										  setInput={setInput}
										  handleEnter={handleEnter}
										  handleSend={handleSend}
										  setLoading={setLoading}
										  setMessages={setMessages}
										  setIsTyping={setIsTyping}										
										  setTypingText={setTypingText} />
										    
								  </div>
							  </div>
							  {/* <div className="col-md-3">
								  <LeftSideBar skills={skills} strengths={strengths} weaknesses={weaknesses} />
							  </div> */}
						  </div>
					  </div>
		  ): (
			<Navigate to="/login" />
		  )
          }		  
		  />   
		  <Route path="*" element={<Navigate to="/" />} />       
        </Routes>
      </div>
    </Router>
  );
}

export default App;
