import React, {useState} from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import ignite_logo from '../assets/ignitebgpurple.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faRightFromBracket, faAnglesLeft } from '@fortawesome/free-solid-svg-icons'; 
import { useNavigate } from 'react-router-dom';

const NavigationBar = ({ toggleSidebar }) => {
  const navigate = useNavigate();
  const [isSidebarHidden, setIsSidebarHidden] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem('userToken');
    localStorage.removeItem('userId');
    localStorage.removeItem('userName');
    navigate('/login');
	window.location.reload();
  };

  const userIsLoggedIn = !!localStorage.getItem('userToken'); 
    // Toggle the sidebar's visibility
	const handleToggleSidebar = () => {
		setIsSidebarHidden(!isSidebarHidden);
		toggleSidebar(!isSidebarHidden);
	  };



  return (
    <Navbar
      bg="dark"
      variant="dark"
      expand="lg"
      className="horizontal-navbar"
      style={{ position: 'fixed', width: '100%', top: 10, zIndex: 1000 }}
    >
      <Navbar.Brand href="/" style={{ fontSize: '24px', fontWeight: 'bolder' }}>
        <img src={ignite_logo} alt="semd" className="bot-image" />IGNITE AI
		
      </Navbar.Brand>

	  {userIsLoggedIn && (
            <button
              className="toggle-sidebar-button"
              onClick={handleToggleSidebar}
            >
				<FontAwesomeIcon icon={faAnglesLeft} beat size="xs" style={{color: "#f1f2f4",}} />
           
            </button>
          )}
	 
      <Navbar.Toggle aria-controls="basic-navbar-nav">
        <FontAwesomeIcon icon={faBars} />
      </Navbar.Toggle>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
		{userIsLoggedIn &&<Nav.Link href="/">Home</Nav.Link>}
          {userIsLoggedIn && <Nav.Link href="/chats">Chats</Nav.Link>}
          {userIsLoggedIn && <Nav.Link href="#reports">Reports</Nav.Link>}
          {userIsLoggedIn && <Nav.Link href="#learn">Learn</Nav.Link>}
          {userIsLoggedIn && <Nav.Link href="#help">Help</Nav.Link>}
          {userIsLoggedIn &&<Nav.Link href="#" onClick={handleLogout}>
              <FontAwesomeIcon icon={faRightFromBracket} beat />
            </Nav.Link>}
			{!userIsLoggedIn &&<Nav.Link href="/login" >
              Login
            </Nav.Link>}
			{!userIsLoggedIn &&<Nav.Link href="/register" >
              Register
            </Nav.Link>}          
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavigationBar;
