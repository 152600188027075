import React, { useState, useEffect } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons'; 
import { Link } from 'react-router-dom';

function Login({ onLogin }) {
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  // eslint-disable-next-line
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false); 
  const navigate = useNavigate();
  const [message, setMessage] = useState({ type: '', text: '' });
  const serverUrl = process.env.REACT_APP_SERVER_URL;

  const handleCloseMessage = () => {
    setMessage({ type: '', text: '' });
  };

  useEffect(() => {
    // Set a timeout to automatically close the alert after 5 seconds
    const timeout = setTimeout(() => {
      handleCloseMessage();
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [message]);
  

  const handleSubmit = async (e) => {
	e.preventDefault();
	try {
	  const response = await axios.post(serverUrl + '/login', {
		mobile,
		password,
	  });
  
	  setMessage({ type: 'success', text: 'Login successful' });
	  const myToken = response.data.token;
	  const userId = response.data.userId;
	  const userName = response.data.username;
	  localStorage.setItem('userToken', myToken);
	  localStorage.setItem('userId', userId);
	  localStorage.setItem('userName', userName);
	  setUserIsLoggedIn(true);
	  onLogin();
	  navigate('/');
	  window.location.reload();

	} catch (error) {
		
		setMessage({ type: 'danger', text: 'Please check the username or password' });
	  // Handle the login error (e.g., show an error message).
	}
  };
  

  return (
	<Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
	 <div  className="bg-white p-5 rounded shadow" style={{ width: '60%', margin: '0 auto', padding: '2rem' }}>
      <h3 style={{ color: '#5A4BFF', fontWeight: 'bolder' }}>Login</h3>
      <Form onSubmit={handleSubmit} style={{ marginTop: '1rem' }}>
        <Form.Group controlId="mobile">
          <Form.Label style={{ fontSize: '1.2rem', fontWeight: 'bold', color: 'grey' }}>
            Mobile Number:
          </Form.Label>
          <Form.Control
            type="text"
            name="mobile"
			autoComplete='mobile'
			placeholder='Enter your Mobile Number'
            value={mobile}
			className='w-100'
            onChange={(e) => setMobile(e.target.value)}
            style={{ marginTop: '0.5rem', marginBottom: '2rem', color: 'grey', fontSize: '1rem' }}
          />
        </Form.Group>
        <Form.Group controlId="password">
          <Form.Label style={{ fontSize: '1.2rem', fontWeight: 'bold', color: 'grey' }}>
            Password:
          </Form.Label>
          <Form.Control
            name="password"
            type="password"
			autoComplete="current-password"
			placeholder='Enter your password'
            value={password}
			className='w-100'
            onChange={(e) => setPassword(e.target.value)}
            style={{ marginTop: '0.5rem', marginBottom: '2rem', color: 'grey', fontSize: '1rem' }}
          />
        </Form.Group>
        <Button variant="primary" className='w-100' type="submit" style={{ fontWeight: 'bold', fontSize: '1.2rem', backgroundColor:'#5A4BFF' }}>
          Login
	  </Button>
	  <Link to="/register" style={{ display: 'block', textAlign: 'center', marginTop: '1rem', fontSize: '1rem', color: '#5A4BFF', textDecoration: 'none' }}>
          Don't have an account? Register here.
        </Link>
    </Form>
   </div>
   {message.text && (
        <Alert key={message.type} variant={message.type} style={{ position: 'absolute', top: '80%', left: '50%', transform: 'translateX(-50%)', padding: '2rem' }}>
          {message.text}
          <Button onClick={handleCloseMessage} variant="outline-secondary" size="sm" style={{ position: 'absolute', top: '0', right: '0', backgroundColor:'red' , color:'white', border:'none'}}>
		  <FontAwesomeIcon icon={faXmark} />
          </Button>
        </Alert>
      )}
</Container>
  );
};

export default Login;
