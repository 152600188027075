import React from 'react';
import sendBtn from '../assets/send.svg';

function ChatInput({ input, setInput, setMessages, setIsTyping,  setLoading, setTypingText, handleEnter, handleSend}) {
  return (
    <div className="inp">
      <input
        type="text"
        placeholder="Send a message"
        value={input}
        onKeyDown={(e) => handleEnter(e, setLoading)}
        onChange={(e) => setInput(e.target.value)}
      />
	  <button className="send" onClick={() => 
		{
			handleSend(input, setMessages, setIsTyping,  setLoading, setTypingText);
			setInput('');
		}}>
		<img src={sendBtn} alt="send" />
	</button>
    </div>
  );
}

function ChatFooter({ input, setInput, setMessages, setIsTyping, setLoading, setTypingText, handleEnter, handleSend }) {
  return (
    <div className="chatFooter">
      <ChatInput
        input={input}
        setInput={setInput}
        handleEnter={handleEnter}
        handleSend={handleSend}
        setLoading={setLoading}
		setMessages={setMessages}
		setIsTyping={setIsTyping}
		setTypingText={setTypingText}
      />
      <p>Ignite AI, All Rights Reserved</p>
    </div>
  );
}

export default ChatFooter;
