import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useEffect } from 'react';


function MainContent({ messages, isTyping, typingText, loading, gptImageLogo, userIcon, msgEnd }) {
	useEffect(() => {
		msgEnd.current.scrollIntoView({ behavior: 'smooth' });
	  }, [messages, typingText, isTyping, msgEnd]);
	  

  return (
      <div className="chats">
        {messages.map((message, i) => (
            <div key={i} className={message.role === 'assistant' ? 'chat bot' : 'chat user'}>
			<img
			  className={`chatImg ${message.role === 'assistant' ? 'bot-image' : ''}`}
			  src={message.role === 'assistant' ? gptImageLogo : userIcon}
			  alt=""
			/>
            <ReactMarkdown className="txt">{message.content}</ReactMarkdown>
          </div>
        ))}

		{loading &&  (
          <div className="chat bot loading">
            <div className="loading-indicator">
              <div className="custom-spinner"></div>
              <div className="custom-message">Querying Ignite AI...</div>
            </div>
          </div>
        )}
		
        {/* Adding typing animation component here */}
        {isTyping && !loading && (
          <div className="chat bot">
            <img className="chatImg bot-image" src={gptImageLogo} alt="" />
            <ReactMarkdown className="txt">{typingText}</ReactMarkdown>
          </div>
        )}

       
        
        <div ref={msgEnd} />
      </div>
  );
}

export default MainContent;
